html {
  margin: 0;
  padding: 0;
  border: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary, blockquote, q, th, td, caption, table, body, div, span, object, iframe, p,
pre, a, abbr, acronym, address,
code, del, dfn, em, img,
dl, dt, dd, ol, ul, li, fieldset,
form, label, legend, tbody, tfoot, thead, tr, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

blockquote, q {
  quotes: "" "";
}
blockquote:before, q:before, blockquote:after, q:after {
  content: "";
}

th, td, caption {
  float: none !important;
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  vertical-align: middle;
}

a img {
  border: none;
}

html {
  height: 100%;
}

body {
  background-color: #238DFA;
  line-height: 1.5;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: #333333;
  font-size: 75%;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjAuNSIgeDI9IjEuMCIgeTI9IjAuNSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzIzOGRmYSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzg1YzBmZCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0%, #238dfa), color-stop(100%, #85c0fd));
  background: -moz-linear-gradient(left, #238dfa, #85c0fd);
  background: -webkit-linear-gradient(left, #238dfa, #85c0fd);
  background: linear-gradient(to right, #238dfa, #85c0fd);
  height: 100%;
  font-family: 'Special Elite', sans-serif;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
body h1, body h2, body h3, body h4, body h5, body h6 {
  font-weight: normal;
  color: #222222;
}
body h1 img, body h2 img, body h3 img, body h4 img, body h5 img, body h6 img {
  margin: 0;
}
body h1 {
  font-size: 3em;
  line-height: 1;
  margin-bottom: 0.50em;
}
body h2 {
  font-size: 2em;
  margin-bottom: 0.75em;
}
body h3 {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 1.00em;
}
body h4 {
  font-size: 1.2em;
  line-height: 1.25;
  margin-bottom: 1.25em;
}
body h5 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.50em;
}
body h6 {
  font-size: 1em;
  font-weight: bold;
}
body p {
  margin: 0 0 1.5em;
}
body p .left {
  float: left;
  margin: 1.5em 1.5em 1.5em 0;
  padding: 0;
}
body p .right {
  float: right;
  margin: 1.5em 0 1.5em 1.5em;
  padding: 0;
}
body a {
  text-decoration: underline;
  color: #06c;
}
body a:visited {
  color: #004d99;
}
body a:focus {
  color: #09f;
}
body a:hover {
  color: #09f;
}
body a:active {
  color: #bf00ff;
}
body blockquote {
  margin: 1.5em;
  color: #666666;
  font-style: italic;
}
body strong, body dfn {
  font-weight: bold;
}
body em, body dfn {
  font-style: italic;
}
body sup, body sub {
  line-height: 0;
}
body abbr, body acronym {
  border-bottom: 1px dotted #666666;
}
body address {
  margin: 0 0 1.5em;
  font-style: italic;
}
body del {
  color: #666666;
}
body pre {
  margin: 1.5em 0;
  white-space: pre;
}
body pre, body code, body tt {
  font: 1em "andale mono", "lucida console", monospace;
  line-height: 1.5;
}
body li ul, body li ol {
  margin: 0;
}
body ul, body ol {
  margin: 0 1.5em 1.5em 0;
  padding-left: 1.5em;
}
body ul {
  list-style-type: disc;
}
body ol {
  list-style-type: decimal;
}
body dl {
  margin: 0 0 1.5em 0;
}
body dl dt {
  font-weight: bold;
}
body dd {
  margin-left: 1.5em;
}
body table {
  margin-bottom: 1.4em;
  width: 100%;
}
body th {
  font-weight: bold;
}
body thead th {
  background: #c3d9ff;
}
body th, body td, body caption {
  padding: 4px 10px 4px 5px;
}
body table.striped tr:nth-child(even) td,
body table tr.even td {
  background: #e5ecf9;
}
body tfoot {
  font-style: italic;
}
body caption {
  background: #eeeeee;
}
body .quiet {
  color: #666666;
}
body .loud {
  color: #111111;
}
body abbr {
  border: 0;
}
body iframe#player {
  position: fixed;
  left: 60px;
  top: 35px;
  border: 3px solid white;
  -moz-box-shadow: #333333 1px 1px 5px 0;
  -webkit-box-shadow: #333333 1px 1px 5px 0;
  box-shadow: #333333 1px 1px 5px 0;
}
body div.videos {
  position: absolute;
  top: 35px;
  left: 795px;
  width: 400px;
  padding-bottom: 15px;
}
body div.videos div {
  padding: 5px 10px;
  margin-bottom: 10px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
body div.videos div.active {
  background-color: #FF5A6B;
  -moz-box-shadow: black 1px 1px 5px 0;
  -webkit-box-shadow: black 1px 1px 5px 0;
  box-shadow: black 1px 1px 5px 0;
}
body div.videos div h2 {
  font-size: 1.25em;
  font-weight: 500;
  text-shadow: #aaaaaa 1px 1px 1px;
  margin-bottom: 0;
}
body div.videos div h2 a {
  color: #F6ED4A;
  text-decoration: none;
}
body div.videos div h3 {
  font-size: 1.2em;
  font-style: italic;
  color: black;
  margin-bottom: 0;
}
body div.videos div h3 a {
  color: black;
  text-decoration: none;
}
body div.videos div h3 a:hover {
  text-decoration: underline;
}
body a.instagram {
  position: fixed;
  left: 60px;
  top: 475px;
  font-size: 4em;
  text-decoration: none;
  font-family: 'Bangers', sans-serif;
  letter-spacing: 3px;
  color: #F6ED4A;
  text-shadow: #ff5a6b 1px 1px 1px;
}
body div#disclaimer {
  position: fixed;
  left: 60px;
  top: 545px;
  width: 700px;
  font-style: italic;
  font-size: 8.8px;
  color: white;
  font-weight: 500;
  text-shadow: #999 1px 1px 1px;
}
body div#info {
  position: fixed;
  top: 498px;
  left: 62px;
  text-align: right;
  font-size: 1.75em;
  width: 700px;
  z-index: -1;
}
body img {
  display: none;
}
